<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="data && data?.id"> ({{ data.id }})</small>
        График выплат по кредиту
      </template>
      <div v-if="loading" class="loading-block">
        <div class="loader"></div>
      </div>
      <div v-else>
        <a-btn-add v-if="loanCount == 0" @click="makeDirLoan()" :title="'Создать  график'" :disabled="loan.status == 2" :icon1="'fas fa-layer-group'" />
        <s-form-loan-schedule v-else-if="loanCount == 1" v-model="data.loan_schedule" :object="{ id: loan.object_id }" class="pb-3" @validate="validate($event)" />
        <div v-else>
          Ошибка получения графика выплат по кредиту
          <a-btn-add @click="fetchLoan()" :title="'Обновить'" :disabled="loan.status == 2" />
        </div>
      </div>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save v-if="data && data.id" @click="submit()"></a-btn-save>

        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel, popupMenu } from "@/components/mixings";
export default {
  mixins: [submitForm, removeEl, getAccess, popupMenu],
  components: {},
  props: {
    value: Boolean,
    category: Number,
    loan: Object,
  },
  data() {
    return {
      data: null,
      loanCount: 0,
      loan_schedule: null,
      loading: false,
      api: "/accounting/dir/loan_payment_schedule",
    };
  },
  created() {
    if (this.id) this.fetchLoan(this.id);
  },
  watch: {
    value(v) {
      if (v && this.id) this.fetchLoan(this.id);
    },
    "loan.credit_payment_schedule_id"(v) {
      if (v) this.fetchLoan();
    },
  },
  computed: {
    id() {
      return this.loan.credit_payment_schedule_id || 0;
    },
  },
  methods: {
    async submit() {
      await this.beforeSave();
      if (this.saving || !this.data?.id) return;
      this.saving = true;
      let data = this.data;
      await this.post(data, this.api);
      this.saving = false;
      return true;
    },
    async makeDirLoan() {
      if (this.loading) return;
      this.loading = true;

      if (!this.loan?.id) return;
      let data = { status: 1, loan_schedule: null };
      let response = await this.$axios.post(this.api, data);
      if (response.data.status == "ok") {
        this.$emit("createSchedule", response.data.data.id);
        this.$root.$emit("show-info", {
          text: "График создан",
        });
        //this.fetchLoan();
      }
      this.loading = !true;
    },
    async fetchLoan(id) {
      if (!id) id = this.id || 0;
      if (id) {
        this.loading = true;
        this.loanCount = -1;
        let res = [];
        let resp = await this.$axios.get(this.api + "/" + id);
        if (resp.data.status === "ok") {
          let data = resp.data.data;

          if (data.id) {
            this.data = data;
            res = this.data.loan_schedule;
          }
        }
        this.loanCount = this.data.id ? 1 : -1;
        this.loan_schedule = res;
      }
      this.loading = !true;
    },
  },
};
</script>
